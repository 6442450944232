



















































import gsap from 'gsap'
import Color from '@/constants/color'
import { Component, Prop, Watch } from 'vue-property-decorator'
import ScrollerHorizontal from '@/components/ScrollerHorizontal.vue'
import SlideBasicCarouselItem from '@/views/SlideBasicCarouselItem.vue'
import ArrowDownNextSlide from '@/components/ArrowDownNextSlide.vue'
import ScrollerElement, { ScrollerElementAnimationEvent } from '@/components/ScrollerElement.vue'
import { mixins } from 'vue-class-component'
import Slide from '@/mixins/Slide'
import BasicIntersectionObservable from '@/mixins/BasicIntersectionObservable'
import { Route } from 'vue-router'
import { Getter } from 'vuex-class'
import { resolveSlug } from '@/utils/alias-resolver'

@Component({
    components: {
        ScrollerElement,
        ScrollerHorizontal,
        SlideBasicCarouselItem,
        ArrowDownNextSlide
    }
})
export default class SlideCarousel extends mixins(Slide, BasicIntersectionObservable) {
    @Prop() isTimeline!: boolean

    @Getter navigationIsAllowed!: boolean

    $refs!: {
        slider: ScrollerHorizontal
        container: HTMLElement
        pagination: HTMLElement
        paginationNumber: Array<HTMLElement>
    }

    currentSlideIndex = 0
    color = Color.LIGHT
    lastPosition = 0
    isPaginationOutOfViewport = false

    get walkerItem(): SlideWalkerItemBasicCarousel {
        return this.walker.item as SlideWalkerItemBasicCarousel
    }

    created() {
        if (this.$route.hash) {
            const slideIndex = this.getSlideIndexByRouteHash()

            if (slideIndex !== -1) this.currentSlideIndex = slideIndex
        }
    }

    mounted(): void {
        if (this.walker.children.length > 1) {
            const hLastPaginationNumberPosition = this.$refs.paginationNumber[
                this.walker.children.length - 1
            ].getBoundingClientRect().right

            if (hLastPaginationNumberPosition > this.windowWidth) {
                this.isPaginationOutOfViewport = true
            }
        }
    }

    resolveWalkerSlug(walker: SlideWalker | PresentationWalker) {
        return resolveSlug(walker)
    }

    onChildCurrentSlideIndexChange(event: { currentSlideIndex: number }) {
        this.currentSlideIndex = event.currentSlideIndex
    }

    goToSlide(index: number): void {
        if (!this.navigationIsAllowed) return

        this.$refs.slider.gotoSlide(index)
    }

    getSlideIndexByRouteHash() {
        return this.walker.children.findIndex(child => {
            return this.$route.hash === '#' + resolveSlug(child)
        })
    }

    populateAppear(timeline: GSAPTimeline) {
        if (this.$refs.container) {
            timeline.fromTo(
                this.$refs.container,
                {
                    opacity: 0,
                    y: 200
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 2,
                    ease: 'power3'
                },
                0.5
            )
        }

        if (this.$refs.pagination) {
            timeline.fromTo(
                this.$refs.pagination,
                {
                    opacity: 0,
                    y: 60
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1.2,
                    ease: 'power3'
                },
                0.8
            )
        }
    }

    populateEnterY({ timeline }: ScrollerElementAnimationEvent) {
        if (this.$refs.container) {
            timeline.fromTo(
                this.$refs.container,
                {
                    opacity: 0,
                    y: 200
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1.6,
                    ease: 'none'
                },
                0.5
            )
        }

        if (this.$refs.pagination) {
            timeline.fromTo(
                this.$refs.pagination,
                {
                    opacity: 0,
                    y: 60
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.8,
                    ease: 'none'
                },
                0.5
            )
        }
    }

    @Watch('$route')
    onRouteChangeInternal(route: Route, previousRoute: Route) {
        if (route.path !== previousRoute.path) return

        const slideIndex = this.getSlideIndexByRouteHash()

        if (slideIndex !== -1) {
            this.currentSlideIndex = slideIndex
        }
    }

    @Watch('currentSlideIndex')
    setPaginationPosition(): void {
        if (this.isTimeline) {
            const pagination = this.$refs.pagination
            const activePaginationNumber = this.$refs.paginationNumber[this.currentSlideIndex]
            const hPaginationPosition = pagination.getBoundingClientRect().x
            const hPaginationNumberPosition = activePaginationNumber.getBoundingClientRect().x
            const hLastPaginationNumberPosition = this.$refs.paginationNumber[
                this.walker.children.length - 1
            ].getBoundingClientRect().right
            const direction = this.$refs.slider.getDirection()

            if (hLastPaginationNumberPosition > this.windowWidth) {
                this.lastPosition = this.currentSlideIndex
                gsap.to(this.$refs.pagination, {
                    x: -1 * (hPaginationNumberPosition - hPaginationPosition - 36),
                    duration: 0.5
                })
            } else if (hLastPaginationNumberPosition <= this.windowWidth && direction === 'right') {
                if (this.lastPosition > this.currentSlideIndex) {
                    gsap.to(this.$refs.pagination, {
                        x: -1 * (hPaginationNumberPosition - hPaginationPosition - 36),
                        duration: 0.5
                    })
                }
            }
        }
    }
}
