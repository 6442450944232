var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scroller-element',{ref:"scrollerElement",attrs:{"y":_vm.y,"index-y":_vm.index}},[_c('div',{class:_vm.$style.root},[_c('scroller-horizontal',{ref:"slider",attrs:{"end-drag-y":_vm.endDragY,"number-slides":_vm.walker.children.length,"parent-current-slide-index":_vm.currentSlideIndex},on:{"current-slide-index-change":_vm.onChildCurrentSlideIndexChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var x = ref.x;
return [_c('div',{ref:"container",class:_vm.$style.container},[_vm._t("item",null,{"x":x,"goToSlide":_vm.goToSlide,"currentSlideIndex":_vm.currentSlideIndex})],2)]}}],null,true)}),(_vm.walker.children.length > 1)?_c('div',{ref:"pagination",staticClass:"pagination",class:[
                _vm.$style.pagination,
                ( _obj = {}, _obj['paginationIsOutOfViewport'] = _vm.isPaginationOutOfViewport, _obj )
            ]},_vm._l((_vm.walker.children),function(child,childIndex){
            var _obj;
return _c('button',{key:_vm.resolveWalkerSlug(child),ref:"paginationNumber",refInFor:true,class:[
                    _vm.$style.paginationNumber,
                    ( _obj = {}, _obj[_vm.$style.paginationNumberIsActive] = childIndex === _vm.currentSlideIndex, _obj[_vm.$style.paginationNumberIsSeparator] = child.item.separatorItem, _obj ),
                    !_vm.navigationIsAllowed && _vm.$style.paginationIsDisabled
                ],attrs:{"type":"button"},on:{"click":function($event){return _vm.goToSlide(childIndex)}}},_vm._l((2),function(i){return _c('span',{key:i,class:_vm.$style.paginationNumberText,attrs:{"aria-hidden":i === 2}},[_vm._t("pagination",null,{"item":child.item,"index":childIndex})],2)}),0)}),0):_vm._e(),(!_vm.isLast)?_c('arrow-down-next-slide',{class:_vm.$style.arrowDown,attrs:{"color":_vm.color}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }